import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "eclipse-marketo-form-root js-formBuilderRoot mc-tradeshow-giveaway-form" }
const _hoisted_2 = { class: "form-completion-message after-complete" }
const _hoisted_3 = { class: "complete" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.showUnlockedView)
      ? (_openBlock(), _createBlock($setup["ContactForm"], {
          key: 0,
          "form-settings-key": $props.formSettingsKey,
          "widget-data": $props.widgetData,
          "custom-data": $props.customData
        }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "custom-data"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createCommentVNode("thank you message"),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.t($setup.ContactFormPostKeysEnum.tradeshowGiveawayMessage, {ns:$setup.TranslationNamespaceEnum.contactPostSubmit})), 1 /* TEXT */)
          ])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}